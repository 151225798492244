const recipient = `
  fragment recipient on Beneficiary {
    name
    email
    creditor {
      name
      identifier 
      city
      country
      postalCode
      stateOrProvince
      address
    }
    paymentType {
      name
    }
  }
`

export {
  recipient
}
