import {
  registerClient,
  getConfiguration,
  getStepConfiguration,
  uploadClientDocument,
  addStepData,
  updateStepData,
  createClient,
  finishStaticStep,
  getOnboardingStatus,
  getClientAddressTypes,
  getCountries,
  getStates,
  updateClient,
  deleteRow
} from '@/api/views/registration/index.js'
import { auth } from '@/api/auth.js'

const getDefaultState = () => {
  return {
    /**
     * We should not store step's data in vuex
     * this fields should be fetch from core as need.
     * Todo: Remove data field in this module
     */
    data: {},
    companyAddress: {
      form1: {},
      form2: {}
    },
    configuration: [],
    cardProducts: [],
    savingsProducts: [],
    currentStepDataTableId: null,
    currentStepPosition: 1,
    latestStepPosition: 1,
    isCompleted: false
  }
}

const state = getDefaultState()

const getters = {
  isCompleted: state => state.isCompleted,
  configuration: state => state.configuration,
  currentStepPosition: state => state.currentStepPosition,
  currentStep: state => state.configuration.find(config => config.position === state.currentStepPosition),
  stepById: state => id => state.configuration.find(config => config.stepId === id),
  companyAddress: state => state.companyAddress
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setConfiguration (state, payload) {
    state.configuration = payload
  },
  setStepConfiguration (state, { stepId, columnHeaders }) {
    state.configuration = state.configuration.map(config => {
      if (config.stepId === stepId) {
        return {
          ...config,
          columnHeaders
        }
      }

      return config
    })
  },
  updateData (state, payload) {
    state.data = {
      ...state.data,
      ...payload
    }
  },
  updateCompanyAddress (state, payload) {
    state.companyAddress.form1 = { ...payload.form1 }
    state.companyAddress.form2 = { ...payload.form2 }
  },
  updateCurrentStepPosition (state, payload) {
    state.currentStepPosition = payload
  },
  updateLatestStepPosition (state, payload) {
    if (state.latestStepPosition > payload) return

    state.latestStepPosition = payload
  },
  isCompleted (state, payload) {
    state.isCompleted = payload
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async getConfiguration ({ commit }) {
    try {
      const template = await getConfiguration()

      commit('setConfiguration', template.data.data.getOnBoardingTemplate.steps)
    } catch (e) {
      throw e
    }
  },
  async getStepConfiguration ({ state, commit }, stepPosition) {
    try {
      const stepId = state.configuration.find(step => step.position === stepPosition).stepId
      const clientId = state.data.client_id
      const template = await getStepConfiguration(stepId, clientId)

      commit('setStepConfiguration', { stepId, columnHeaders: template.data.data.getStepDetails.columnHeaders })

      return template.data.data.getStepDetails
    } catch (e) {
      throw e
    }
  },
  async getOnboardingStatus ({ commit, getters }) {
    const clientId = sessionStorage.getItem('clientId')

    if (!clientId) return

    const result = await getOnboardingStatus(clientId)
    const currentStepId = result?.data?.data?.getOnBoardingStatus?.nextStep

    if (currentStepId === 0) {
      commit('isCompleted', true)
      // eslint-disable-next-line camelcase
      commit('updateData', { client_id: clientId })
    } else if (currentStepId > 0) {
      const position = getters.stepById(currentStepId).position
      commit('updateCurrentStepPosition', position)
      commit('updateLatestStepPosition', position)
      // eslint-disable-next-line camelcase
      commit('updateData', { client_id: clientId })
    }
  },
  async registerClient ({ commit }, payload) {
    try {
      const result = await registerClient(payload)

      if (result.data.errors) {
        throw result.data
      }

      // eslint-disable-next-line camelcase
      commit('updateData', { ...payload, app_user_id: result.data.data.registerUnverifiedOrgUser })

      await auth({
        username: payload.email,
        password: payload.password
      })

      return result
    } catch (e) {
      throw e
    }
  },
  async createClient ({ commit, state }, payload) {
    const result = await createClient(state.data.app_user_id, `${payload.firstName} ${payload.lastName}`)

    if (result.data.errors) {
      throw result.data
    }
    sessionStorage.setItem('clientId', result.data.data.registerOnBoardingClient)
    // eslint-disable-next-line camelcase
    commit('updateData', { client_id: result.data.data.registerOnBoardingClient })
  },
  goBack ({ commit, state }) {
    commit('updateCurrentStepPosition', state.currentStepPosition - 1)
  },
  async goNext ({ commit, state, getters, dispatch }, payload = {}) {
    const { data, step } = payload

    const getFieldToSubmit = async (formModel, step) => {
      const fields = step.columnHeaders
        .map(step => ({ columnName: step.columnName, columnType: step.columnType }))

      const result = {}

      await Promise.all(fields.map(async ({ columnName, columnType }) => {
        if (columnName === 'client_id') result[columnName] = state.data[columnName]

        else if (columnName.includes('FILE_UPLOAD')) {
          if (!formModel[columnName]) return
          if (formModel[columnName].name) {
            const fileId = await uploadClientDocument({
              clientId: state.data.client_id,
              formData: {
                name: formModel[columnName].name,
                file: formModel[columnName]
              }
            })
            result[columnName] = fileId.data.id
          }
        } else if (columnType === 'multiselect') {
          result[columnName] = (formModel[columnName] && typeof formModel[columnName] !== 'string') ? formModel[columnName].join() : formModel[columnName]
        } else {
          result[columnName] = formModel[columnName]
        }
      }))

      return result
    }

    const submit = async (fieldsToSubmit, { stepId, position }, resourcePath, isUpdatingData) => {
      const apiRequest = isUpdatingData ? updateStepData : addStepData
      const submitData = {
        ...fieldsToSubmit,
        dateFormat: state?.user?.dateFormat || 'yyyy-MM-dd',
        locale: state?.user?.locale || 'en'
      }

      const response = await apiRequest({
        stepId: stepId,
        stepPosition: position,
        clientId: state.data.client_id,
        data: JSON.stringify(submitData).replace(/"([^(")"]+)":/g, '$1:'),
        originalData: fieldsToSubmit,
        resourcePath
      })

      const changes = isUpdatingData
        ? response.data.data.updateUnverifiedOnBoardingStep?.changes
        : response.data.data.createUnverifiedOnBoardingStep?.changes

      if (!changes) throw response.data.errors
    }

    if (step && !step.isMultiRow) {
      const isUpdatingData = data[0].id
      const fieldsToSubmit = await getFieldToSubmit(data[0], step, isUpdatingData)
      await submit(fieldsToSubmit, step, step.resourcePath, isUpdatingData)
    }

    if (step && step.isMultiRow) {
      for await (const model of data) {
        const isUpdatingData = model.id
        const fieldsToSubmit = await getFieldToSubmit(model, step, isUpdatingData)
        await submit(fieldsToSubmit, step, step.resourcePath, isUpdatingData)
      }
    }

    if (!step) {
      await finishStaticStep(getters.currentStep.stepId, state.data.client_id)
    }

    await dispatch('updateOnboardStatus')

    commit('updateData', payload)
  },
  async updateOnboardStatus ({ state, commit, getters }) {
    try {
      if (state.currentStepPosition === state.latestStepPosition) {
        const result = await getOnboardingStatus(state.data.client_id)

        if (result?.data?.data?.getOnBoardingStatus?.nextStep === 0) return commit('isCompleted', true)

        const nextStep = getters.stepById(result?.data?.data?.getOnBoardingStatus?.nextStep)

        commit('updateCurrentStepPosition', nextStep.position)
        commit('updateLatestStepPosition', nextStep.position)
      } else {
        commit('updateCurrentStepPosition', state.currentStepPosition + 1)
      }
    } catch (e) {
      throw e
    }
  },
  getClientAddressTypes () {
    return getClientAddressTypes()
  },
  getCountries () {
    return getCountries()
  },
  getStates (_context, countryId) {
    return getStates(countryId)
  },
  updateClient (_context, data) {
    return updateClient(data)
  },
  deleteRow (_context, payload) {
    const clientId = sessionStorage.getItem('clientId')
    return deleteRow(payload.rowId, payload.stepId, clientId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
