/* eslint-disable camelcase */
import { listTickets as getTickets } from '@/api/zendesk'

const getDefaultState = () => {
  return {
    tickets: undefined
  }
}

const state = getDefaultState()

const getters = {
  tickets: (state) => state.tickets
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState)
  },
  fetchTickets (state, tickets) {
    state.tickets = tickets
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async fetchTickets ({ commit, rootGetters }) {
    try {
      const email = rootGetters['user/email']
      const tickets = await getTickets(email)
      commit('fetchTickets', tickets)
    } catch (error) {
      console.error(`Error fetching zendesk tickets`, error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
