const card = `
  fragment card on Card {
    id
    expiresOn
    primaryAccountNumber
    status
    embossedNames
    activatedAt
    virtual
    physicalCardActivated
    digitalFirst
    fulfillmentStatus
    account {
      id
      accountNumber
      minimumRequiredOpeningBalance
      overdraftLimit
      minimumRequiredBalanceEnforced
      onHoldFunds
      savingsOnHoldAmount
      summary {
        accountBalance
      }
      currency {
        code
      }
    }
    product {
      name
      network
      velocityRules {
        id
        value
        timeUnit
        controls
      }
    }
    onlinePaymentEnabled
    contactlessPaymentEnabled
    atmWithdrawalsEnabled
    internationalPaymentsEnabled
    blockedCountries
    subStatus
    velocityRules {
      timeUnit
      value
      controls
      type
      timePeriod
      id
    }
  }
`

export { card }
